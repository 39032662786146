<template>
  <v-card
    flat
    tile
    color="transparent"
    class="font-montserrat text-wrap break-word login-slogan-text font-weight-black normal-line-height white--text px-12 mx-5 py-7"
    width="370"
  >
    <span v-html="sloganText"></span>
  </v-card>
</template>
<script>
export default {
  name: "SloganDesktopLogin",

  computed: {
    sloganText() {
      return this.$t("login.slogan");
    },
  },
};
</script>
<style>
.login-text-spotlight {
  color: var(--v-tertiarySuite-base);
  background-color: rgba(78, 58, 100, 0.9);
  padding: 0px;
  opacity: 0.8;
  animation: typing 2s steps(22);
}

.login-slogan-text {
  font-size: 38px;
}

@keyframes typing {
  from {
    width: 0;
  }
}
</style>
