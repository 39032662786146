<template>
  <v-app id="login" class="new-login primary pa-0 ma-0">
    <v-container fluid class="primary fill-height ma-0 pa-0">
      <v-flex d-flex child-flex class="primary fill-height pa-0 ma-0">
        <v-row no-gutters class="ma-0 pa-0" style="width: 100%; height: 100%">
          <v-col v-if="!isMobile" :cols="6" class="fill-height pa-0 ma-0">
            <div>
              <SloganDesktopLogin style="position: absolute; z-index: 1" />

              <v-img
                ref="backgroundMusic"
                height="100vh"
                position="10% 5%"
                src="@/assets/images/login.jpg"
              />
            </div>
          </v-col>
          <v-col :cols="isMobile ? 12 : 6">
            <v-sheet
              class="fill-height d-flex flex-column justify-space-between"
            >
              <v-card
                ref="loginCard"
                tile
                flat
                color="transparent"
                class="mx-auto rounded-xl"
                :class="isMobile ? 'login-card' : 'mt-9'"
                :style="isMobile ? `margin-top: ${loginMarginTop}px` : ''"
                width="620"
              >
                <div style="max-width: 410px" class="mt-9 mx-auto">
                  <div class="login-welcome-text">
                    {{ $t("login.welcomeTo") }}
                  </div>
                  <div>
                    <v-img
                      contain
                      :src="logoConectaSuiteFull"
                      :width="256"
                      :height="40"
                    />
                  </div>
                </div>

                <div style="max-width: 410px" class="mt-8 mb-10 mx-auto">
                  <v-tabs
                    v-model="tab"
                    centered
                    grow
                    height="42"
                    color="#613FC8CC"
                    slider-size="3"
                  >
                    <v-tab
                      tab-value="signIn"
                      class="text-none flex-column px-0"
                    >
                      <div
                        class="d-flex flex-grow-1 flex-shrink-0 align-center justify-center px-4"
                      >
                        <div class="login-tab-text">
                          {{ $t("login.signIn") }}
                        </div>
                      </div>
                      <v-tabs-slider
                        class="flex-grow-0 flex-shrink-1"
                        color="#E6E6E6"
                        style="height: 3px"
                      />
                    </v-tab>
                    <v-tab tab-value="logIn" class="text-none flex-column px-0">
                      <div
                        class="d-flex flex-grow-1 flex-shrink-0 align-center justify-center px-4"
                      >
                        <div class="login-tab-text">
                          {{ $t("login.logIn") }}
                        </div>
                      </div>
                      <v-tabs-slider
                        class="flex-grow-0 flex-shrink-1"
                        color="#E6E6E6"
                        style="height: 3px"
                      />
                    </v-tab>
                  </v-tabs>
                </div>

                <v-tabs-items v-model="tab">
                  <v-tab-item value="signIn">
                    <CreateAccountWithEmail />
                  </v-tab-item>
                  <v-tab-item value="logIn">
                    <LoginActions />
                  </v-tab-item>
                </v-tabs-items>
                <div
                  class="d-flex justify-center text-center"
                  :class="tab == 'signIn' ? 'pb-3 mb-10' : 'mb-8'"
                >
                  <SelectLanguage />
                </div>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-flex>

      <!-- ERROR MESSAGE -->
      <MarketplaceDialog
        v-if="isAppError && !isAccessTokenError"
        :show="showMarketplaceDialog"
        @close="closeMarketplaceDialog"
      />

      <ScopesDialog
        v-if="!isAppError && isAccessTokenError"
        :show="showScopesDialog"
        @close="closeScopesDialog"
      />

      <ErrorDialog
        v-if="!isAppError && !isAccessTokenError"
        :show="hasAnError"
        :error-message="getErrorMessage"
        @close="setAuthError(false)"
      />
    </v-container>
  </v-app>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

import ErrorDialog from "@/components/general/ErrorDialog.vue";
import SloganDesktopLogin from "@/components/login/SloganDesktopLogin.vue";
import MarketplaceDialog from "@/components/login/MarketplaceDialog.vue";
import ScopesDialog from "@/components/login/ScopesDialog.vue";
import SelectLanguage from "@/components/new_login/SelectLanguage";

import LoginActions from "@/components/new_login/LoginActions.vue";
import CreateAccountWithEmail from "@/components/new_login/CreateAccountWithEmail.vue";

import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Login",

  components: {
    ErrorDialog,
    LoginActions,
    SloganDesktopLogin,
    MarketplaceDialog,
    ScopesDialog,
    CreateAccountWithEmail,
    SelectLanguage,
  },

  data: () => ({
    logoConectaSuiteFull: require("@/assets/appslogos/full/conecta_suite_full.png"),
    showMarketplaceDialog: true,
    showScopesDialog: true,
    loginCard: {},
    tab: "logIn",
  }),

  computed: {
    ...mapGetters(["authError"]),

    hasAnError() {
      return !!this.authError;
    },

    isAppError() {
      return this.authError === "MARKETPLACE_APP_NOT_INSTALLED";
    },

    isAccessTokenError() {
      return this.authError === "INVALID_GOOGLE_ACCESS_TOKEN";
    },

    getErrorMessage() {
      const unknownError =
        "Erro desconhecido. Entre em contato com <a href='mailto:suporte@conectasuite.com'>suporte@conectasuite.com</a>";

      return this.authError
        ? errorMessages[this.authError] || unknownError
        : unknownError;
    },

    logoHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 60;
      } else if (this.$vuetify.breakpoint.md) {
        return 70;
      } else if (this.$vuetify.breakpoint.sm) {
        return 60;
      } else {
        return 40;
      }
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    loginMarginTop() {
      const offsetHeight = (this.loginCard.offsetHeight || 0) + 24;
      const windowHeight = this.$vuetify.breakpoint.height;
      const marginTop = (windowHeight - offsetHeight) * 0.36;
      if (offsetHeight >= windowHeight || marginTop < 36) return 36;
      else return marginTop;
    },
  },

  methods: {
    ...mapMutations(["setAuthError"]),

    closeMarketplaceDialog() {
      this.showMarketplaceDialog = false;
    },
    closeScopesDialog() {
      this.showScopesDialog = false;
    },
  },

  mounted() {
    this.loginCard = this.$refs.loginCard.$el;
  },

  async beforeMount() {
    const auth = getAuth();
    if (auth.currentUser) {
      await signOut(auth);
    }
  },
};
</script>

<style lang="scss">
.c-font-poppins {
  font-family: Poppins !important;
}

.c-font-montserrat {
  font-family: Montserrat !important;
}

.c-font-size-12 {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14.63px !important;
}

.c-font-size-13 {
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 15.85px !important;
}

.c-font-size-14 {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px !important;
}

.c-text-common {
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
}

.login-card {
  border: 1px solid #fdfdfd !important;
  box-shadow: 0px 4px 30px 0px #0000001f !important;
}

.login-welcome-text {
  @extend .c-font-poppins;
  @extend .c-text-common;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #7f7f7f;
}

.input-label {
  @extend .c-font-montserrat;
  @extend .c-font-size-14;
  @extend .c-text-common;
  text-align: left;
  color: black;
}

.enter-text {
  @extend .c-font-montserrat;
  @extend .c-font-size-14;
  @extend .c-text-common;
  text-align: left;
}

.forgot-password {
  @extend .c-font-montserrat;
  @extend .c-font-size-12;
  @extend .c-text-common;
  text-align: left;
  color: #4285f4 !important;
}

.login-wrong-credentials-text {
  @extend .c-font-montserrat;
  @extend .c-font-size-12;
  @extend .c-text-common;
  text-align: left;
  color: #af233a !important;
}

.login-submit-button {
  box-shadow: 0px 3.63px 17.22px 0px #7793414d !important;
}

.login-footer-text {
  @extend .c-font-montserrat;
  @extend .c-font-size-12;
  text-align: center;
  color: #373737 !important;
}

.login-footer-text a {
  @extend .c-text-common;
  color: #0089ed !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.login-action-text {
  @extend .c-font-montserrat;
  @extend .c-font-size-13;
  @extend .c-text-common;
  text-align: left;
  color: black;
}

.login-tab-text {
  @extend .c-font-poppins;
  @extend .c-text-common;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.login-checkbox-text {
  @extend .c-font-montserrat;
  @extend .c-font-size-12;
  @extend .c-text-common;
  text-align: left;
  color: #373737 !important;
}

.login-checkbox-text a {
  text-decoration-line: underline;
  text-decoration-style: solid;
  color: #4285f4 !important;
}

.new-login .v-text-field input {
  @extend .c-font-montserrat;
  @extend .c-text-common;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.63px;
  text-align: left;
  color: #909090;
  padding-left: 14px;
}

.new-login .v-btn {
  letter-spacing: normal;
}

.create-account-with-email .v-text-field__details {
  margin-top: 2px !important;
  padding-left: 0px !important;
}

.create-account-with-email .v-messages__message {
  @extend .c-font-montserrat;
  @extend .c-font-size-12;
  @extend .c-text-common;
  text-align: left;
  color: #ff0000cc;
}
</style>
